import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const separator = cva(undefined, {
  variants: {
    style: {
      border: ['border-solid'],
      dashed: ['border-dashed'],
      dotted: ['border-dotted'],
      double: ['border-double']
    },
    color: {
      primary: ['border-primary'],
      secondary: ['border-secondary'],
      accent1: ['border-accent1'],
      accent2: ['border-accent2'],
      accent3: ['border-accent3'],
      success: ['border-success'],
      white: ['border-white'],
      error: ['border-red']
    },
    titleAlignment: {
      left: ['pr-4'],
      center: ['px-4'],
      right: ['pl-4']
    }
  },
  defaultVariants: {
    style: 'border',
    color: 'primary',
    titleAlignment: 'left'
  }
})

export type SeparatorProps = VariantProps<typeof separator>
